import { VpEnvironment } from '@zeiss/ng-vis-common/types';

export const Environment = {
	AppMeta: {
		Environment: VpEnvironment.QAS,
		Root: 'https://portal-qas.vision.zeiss.com',
	},
	AppInsights: {
		Activated: document.URL.includes('localhost') ? false : true,
		InstrumentationKey: 'bcd1bdb2-318f-42a3-bcf1-0e189dd66d49',
		TENANT_ID: '8cd48dd0-e5b6-42f4-946a-84377e7b1185',
		SUBSCRIPTION_ID: 'b1c34dd8-4c03-4040-8d17-49f42f2aa49b',
		RESOURCE_GROUP: 'PORTAL-QAS',
		APP_INSIGHTS_INSTANCE_NAME: 'visionportal-ai-qas',
	},
	LOI: {
		Path: 'https://loi-qas.lodb.org/',
	},
};
